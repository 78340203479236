<template>
  <div>
    <div class="mb-3">
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            {{ myTitle }}
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <Heading heading="h4" :content="myTitle"></Heading>
      <small> View {{ myTitle }} </small>
      <hr />
      <Pro
        v-if="info"
        title="Licenses for Users"
        :point="info.coins"
      />
      <hr />
      <el-input
        class="mt-3"
        ref="filterInput"
        placeholder="Name / Email"
        v-model="search"
        @keyup.enter.native="searchResult"
      >
        <el-button
          el-button
          slot="append"
          icon="el-icon-search"
          @click="searchResult"
        >
          Search
        </el-button>
      </el-input>
      <div class="text-center mt-3 mb-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="10"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <el-table
        v-if="users"
        :size="isPhone ? 'mini' : ''"
        class="mt-4"
        :data="users"
        style="min-width: 100%"
        border
      >
        <el-table-column label="Name">
          <template slot-scope="scope">
            <b>
              {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
            </b>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="Email">
          <template slot-scope="scope">
            <b class="text-success"> {{ scope.row.email }}</b>
          </template>
        </el-table-column>
        <!-- <el-table-column label="Role" min-width="100">
          <template slot-scope="scope">
            <template v-if="scope.row.roles && scope.row.roles.length > 0">
              <span v-for="(role, index) in scope.row.roles" :key="index">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Company Admin"
                  placement="top"
                >
                  <el-tag
                    v-if="role.name === 'organization_admin'"
                    type="success"
                    class="mr-2 max-color"
                  >
                    <i class="fas fas fa-building"></i>
                  </el-tag>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Educator"
                  placement="top"
                >
                  <el-tag
                    v-if="role.name === 'teacher'"
                    type="success"
                    class="mr-2 max-color"
                  >
                    <i class="fas fa-chalkboard-teacher"></i>
                  </el-tag>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Super Admin"
                  placement="top"
                >
                  <el-tag
                    v-if="role.name === 'super_admin'"
                    type="warning"
                    class="mr-2"
                  >
                    <i class="fas fa-crown" />
                  </el-tag>
                </el-tooltip>
              </span>
            </template>
            <template v-else>
              <el-tooltip
                class="item"
                effect="dark"
                content="Student"
                placement="top"
              >
                <el-tag type="success">
                  <i class="fas fa-user-graduate"></i>
                </el-tag>
              </el-tooltip>
            </template>
          </template>
        </el-table-column> -->
        <el-table-column label="Plans">
          <template slot-scope="scope">
            <!-- <el-tag type="info" class="mb-2 mr-2">
              <b>Basic</b>
            </el-tag> -->
            <el-tag
              class="test-color mb-2 mr-2"
              v-for="plan in scope.row.subscriptions"
              :key="plan.id"
            >
              <b>{{ plan.subscription_plan.name }}</b>
            </el-tag>
            <el-button type="text" @click="selectUser(scope.row)">
              <i class="fa fa-plus"></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column label="Date Registered" width="150">
          <template slot-scope="scope">
            {{ instant.formatGoogleTime(scope.row.created_at) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center mt-3">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="10"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <StudentsDialog
        ref="StudentsDialog"
        :showDialog="studentPlansShow"
        :userNow="userNow"
        :usePoints="true"
        :companyId="info.id"
        :subscriptions="userNow ? userNow.subscriptions : []"
        @updateUsers="updateCompany"
      ></StudentsDialog>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Pro from "@/views/company/components/Pro.vue";
import Company from "@/views/company/apis/company.js";

import StudentsDialog from "@/views/plans/components/StudentsDialog";

export default {
  metaInfo() {
    return {
      title: "Company" + this.myTitle + " - " + this.CompanyName
    };
  },

  components: { Breadcrumb, Pro, StudentsDialog },

  mixins: [],

  props: [],
  data() {
    return {
      info: null,
      users: null,
      userNow: null,
      search: "",
      roles: ["Admins", "Educators", "Students"],
      resultsTotal: 1,
      pageSize: 10,
      companies: null
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return "Students";
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    },
    companyId() {
      return this.companies ? this.companies[0].id : null;
    }
  },
  watch: {
    companyId() {
      if (this.companyId) {
        this.getInfo();
        this.getCompanyUsers();
      }
    }
  },

  async mounted() {
    this.getCompanies();
  },

  methods: {
    async getCompanies() {
      const res = await Company.getCompanies({
        page: 1
      });
      this.companies = res.data;
    },
    updateCompany() {
      if (this.companyId) {
        this.getInfo();
        this.getCompanyUsers();
      }
    },
    selectUser(user) {
      this.userNow = null;
      this.userNow = user;
      this.selectStudent();
    },
    selectStudent() {
      this.$refs.StudentsDialog.selectStudent();
    },
    async getInfo() {
      const res = await Company.getCompanyDetail(this.companyId);
      this.info = res;
    },
    async getCompanyUsers() {
      const res = await Company.getCompanyUsers(this.companyId, {
        role: "member",
        page: this.page
      });
      this.users = res.data;
      this.resultsTotal = res.total;
    },
    searchResult() {},
    selectRole(user) {
      this.activeUser = null;
      this.activeUser = user;
      this.showRole = true;
      if (user.all_roles.indexOf("teacher") > -1) {
        this.isTeacher = true;
      } else {
        this.isTeacher = false;
      }
    }
  }
};
</script>

<style scoped>
.free-color {
  background-color: #01b884;
}
.pro-color {
  background-color: #fa17e3;
}
.max-color {
  background-color: #1f7cff;
}
.free-color,
.pro-color,
.max-color {
  color: white;
  border: none;
}
</style>
